import { Typography } from '@grupo-sbf/motriz-nike';
import { media } from '@grupo-sbf/motriz-utils';
import styled, { css } from 'styled-components';
import Button from '@/src/common/components/Button/Button';

export const ContainerHeader = styled.div`
  ${({ theme }) => css`
    margin-bottom: ${theme.spacing[200]};

    > :first-child {
      margin-top: ${theme.spacing[400]};
    }
  `}
`;

export const TitleHeader = styled(Typography).attrs({
  type: 'subtitle',
  as: 'h1',
})`
  ${({ theme }) => css`
    padding: ${theme.spacing[100]} ${theme.spacing[500]};
    text-align: center;
  `}
`;

export const ParagraphHeader = styled(Typography).attrs({
  type: 'title',
  as: 'h3',
})`
  text-align: center;
`;
export const ParagraphGreeting = styled(Typography).attrs({
  type: 'paragraph',
})`
  text-align: center;
`;

export const ContainerContent = styled.div`
  ${({ theme }) => css`
    margin: 0 auto;
    max-width: 902px;
    padding: ${theme.spacing[200]};
  `}
`;

export const GridContent = styled.div`
  ${({ theme }) => css`
    display: grid;
    grid-template-columns: 1fr;
    margin-bottom: ${theme.spacing[1000]};
    margin-top: ${theme.spacing[400]};
  `}
`;

export const WrapperRequest = styled.div`
  text-align: center;
`;

export const HeaderRequest = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex-direction: column;
    gap: ${theme.spacing[200]};
    margin-top: ${theme.spacing[700]};
    margin-bottom: ${theme.spacing[400]};
  `}
`;

export const CardsArea = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex-direction: column;
    gap: ${theme.spacing[200]};
    margin-bottom: ${theme.spacing[500]};
    display: grid;

    ${media.greaterThan('medium')} {
      grid-template-columns: repeat(2, 1fr);
      gap: ${theme.spacing[300]};
    }
  `}
`;

export const WrapperProduct = styled.div`
  ${({ theme }) => css`
    border-color: ${theme.color.neutral[300]};
    border-radius: ${theme.borderRadius.large};
    border-style: solid;
    border-width: ${theme.borderWidth.xthin};
    display: flex;
    flex-direction: column;
    gap: ${theme.spacing[600]};

    ${media.greaterThan('large')} {
      gap: ${theme.spacing[700]};
    }
  `}
`;

export const ButtonHome = styled(Button).attrs({
  variant: 'secondary',
})`
  ${media.lessThan('large')} {
    width: 100%;
  }
`;
