import { Typography } from '@grupo-sbf/motriz-nike';
import Link from 'next/link';
import { useState } from 'react';
import Button from '@/src/common/components/Button/Button';
import ReturnModal from '@/src/common/components/ReturnModal/ReturnModal';
import { getCoreRoutes } from '@/src/common/config/routes';
import { trackingEvent } from '@/src/common/utils/tracking';
import {
  ContainerType,
  ReturnBox,
  ContainerTypeBtn,
  ReturnLink,
} from './ProductReturn.styled';

const handleReturnClick = () => {
  trackingEvent({
    event: 'select_content',
    content_type: 'button',
    item_id: 'return_button',
  });
};

const handleReturnOnStoreClick = () => {
  trackingEvent({
    event: 'select_content',
    content_type: 'button',
    item_id: 'return_store_button',
  });
};

const ProductReturn = () => {
  const coreRoutes = getCoreRoutes();
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const openModal = () => {
    trackingEvent({
      event: 'select_content',
      content_type: 'link',
      item_id: 'return_help',
    });
    setIsOpen(true);
  };

  const closeModal = () => {
    setIsOpen(false);
  };

  return (
    <ReturnBox>
      <ContainerType>
        <Typography type="subtitle">Devolução de produto</Typography>

        <Typography type="paragraph">
          Devolva seu produto pelos Correios ou em lojas especifícas da Nike
          para trocas e devoluções.
        </Typography>

        <Typography type="paragraph">
          Ao devolver seu produto, você pode escolher entre receber um
          vale-troca ou solicitar o reembolso. Optando pela devolução em uma
          loja Nike, você pode trocar o produto ali mesmo.
        </Typography>

        <ReturnLink onClick={openModal} hasUnderline>
          {' '}
          Entenda como funciona a devolução por correios
        </ReturnLink>
      </ContainerType>
      <ReturnModal isOpen={isOpen} onClose={closeModal} />
      <ContainerTypeBtn>
        <Link href={coreRoutes.returnRoute} passHref>
          <Button variant="primary" as="a" onClick={handleReturnClick}>
            Devolver via Correios
          </Button>
        </Link>
        <Link href={coreRoutes.storesRoute} passHref>
          <Button variant="secondary" as="a" onClick={handleReturnOnStoreClick}>
            Trocar na loja
          </Button>
        </Link>
      </ContainerTypeBtn>
    </ReturnBox>
  );
};

export default ProductReturn;
